<template>

  <div class="page-container">

    <img class="logo" src="@/assets/images/esprezzo-logo.png" />

    <div ref="containerElement" class="container">

      <LoadingOverlay v-if="form.isLoading" />

      <template v-if="currentStep === 1">
        <PasswordForm
          :submit="submit"
          :form-name="formName"
          title="Reset your password"
          button-title="Reset password"
        />
      </template>

      <template v-if="currentStep === 2">
        <h4>Reset your password</h4>
        <div class="alert success">
          Your password has been successfully reset! Click the button below to
          return to your Dashboard.
        </div>
        <router-link class="mt-8 button" to="/dashboard">Go to Dashboard</router-link>
      </template>

    </div>

  </div>

</template>

<script>

  import useForm from '@/composables/useForm'
  import useBeforeUnload from '@/composables/useBeforeUnload'

  import PasswordForm from '@/components/forms/PasswordForm.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'

  export default {
    components: {
      PasswordForm,
      LoadingOverlay,
    },
    setup() {

      // data
      const formName = 'resetPasswordForm'

      // composables
      const { form, scrollToFormTop, containerElement } = useForm({ formName })

      const { removeBeforeUnloadListener } = useBeforeUnload({ message: 'Are you sure you want to leave this page without setting a password?' })

      return {
        form,
        formName,
        scrollToFormTop,
        containerElement,
        removeBeforeUnloadListener,
      }

    },
    data() {
      return {
        currentStep: 1,
      }
    },
    methods: {
      submit($event) {
        if ($event) $event.preventDefault()

        this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
          .then((result) => {
            this.removeBeforeUnloadListener()
            this.currentStep = 2
          })
          .catch(() => {
            // do nothing
          })
          .finally(() => {
            this.scrollToFormTop()
          })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/logged-out-page.styl'

</style>
